import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UpdateProfile from 'views/profile/UpdateProfile';
import UpdateSecurity from 'views/profile/UpdateSecurity';
import RoleGuard from 'utils/route-guard/RoleGuard';
import Error from 'views/pages/maintenance/Error';

import WeeklyResultForm from 'views/weekly-result/WeeklyResultForm';
import WeeklyResultList from 'views/weekly-result/WeeklyResultList';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Profile Routing
const AppProfile = Loadable(lazy(() => import('views/profile/Profile')));

// Clients Routing
const AppClientList = Loadable(lazy(() => import('views/client/ClientList')));
const AppUpdateClient = Loadable(lazy(() => import('views/client/UpdateClient')));
const AppClientDetail = Loadable(lazy(() => import('views/client/ClientDetail')));
const AppInvoiceList = Loadable(lazy(() => import('views/client/invoice/InvoiceList')));
const AppUpdateInvoice = Loadable(lazy(() => import('views/client/invoice/UpdateInvoice')));
const AppQuotationList = Loadable(lazy(() => import('views/client/quotation/QuotationList')));
const AppUpdateQuotation = Loadable(lazy(() => import('views/client/quotation/UpdateQuotation')));
const AppQuotationLeadList = Loadable(lazy(() => import('views/quotations/quotation/QuotationList')));
const AppUpdateQuotationLead = Loadable(lazy(() => import('views/quotations/quotation/UpdateQuotation')));
const AppClientProfileEdit = Loadable(lazy(() => import('views/client/UpdateClientProfile')));
const AppClientConnectionEdit = Loadable(lazy(() => import('views/client/UpdateClientConnection')));
const AppClientProfile = Loadable(lazy(() => import('views/client/clientProfile')));
const AppClientHistory = Loadable(lazy(() => import('views/client/clientHistory')));
const AppAttachmentClientList = Loadable(lazy(() => import('views/client/attachment/AttachmentList')));
const AppUsersList = Loadable(lazy(() => import('views/client/users/UserList')));
const AppClientPerformaList = Loadable(lazy(() => import('views/client/Performa/PerformaList')));
const AppAddClientPerforma = Loadable(lazy(() => import('views/client/Performa/UpdateProforma')));
const AppNotesList = Loadable(lazy(() => import('views/client/notes/clientnotes')));

// Sample REquest Routing
const AppSampleRequestList = Loadable(lazy(() => import('views/sample-request/SampleRequestList')));
const AppAddSampleRequestList = Loadable(lazy(() => import('views/sample-request/UpdateSampleLead')));
const AppSampleDetail = Loadable(lazy(() => import('views/sample-request/SampleDetail')));
const AppSampleProfile = Loadable(lazy(() => import('views/sample-request/SampleProfile')));
const AppSampleAssignEdit = Loadable(lazy(() => import('views/sample-request/assign/AssignSample')));
const AppSampleHistory = Loadable(lazy(() => import('views/sample-request/SampleHistoryTab')));
// const AppSampleOrdersList = Loadable(lazy(() => import('views/customer-request/orders/OrdersList')));
// social Lead Routing
const AppSocialLeadList = Loadable(lazy(() => import('views/social-lead/socialLeadList')));
const AppAddSocialLead = Loadable(lazy(() => import('views/social-lead/UpdateLead')));
const AppSocialLeadDetail = Loadable(lazy(() => import('views/social-lead/LeadDetail')));
const AppSocialLeadProfile = Loadable(lazy(() => import('views/social-lead/LeadProfile')));
const AppSampleLeadHistory = Loadable(lazy(() => import('views/social-lead/LeadHistoryTab')));
const AppSocialAssignEdit = Loadable(lazy(() => import('views/social-lead/assign/AssignLead')));
const AppSocialQuotationList = Loadable(lazy(() => import('views/social-lead/quotation/QuotationList')));
const AppUpdateSocialQuotation = Loadable(lazy(() => import('views/social-lead/quotation/UpdateQuotation')));
const AppSocialLeadAssignEdit = Loadable(lazy(() => import('views/social-lead/assign/AssignLead')));

// Registerd customer routing
const AppCustomerRequestList = Loadable(lazy(() => import('views/customer-request/CustomerRequestList')));
const AppAddCustomerRequest = Loadable(lazy(() => import('views/customer-request/UpdateCustomerRequest')));
const AddCustomerBasicDetail = Loadable(lazy(() => import('views/customer-request/CustomerBasicDetail')));
const AddCustomerBankDetail = Loadable(lazy(() => import('views/customer-request/CustomerBankDetails')));
const AppCustomerDetail = Loadable(lazy(() => import('views/customer-request/CustomerDetail')));
const AppCustomerProfile = Loadable(lazy(() => import('views/customer-request/CustomerProfile')));
const CustomerBankInfo = Loadable(lazy(() => import('views/customer-request/CustomerBankInfo')));
const AppCustomerBankHistory = Loadable(lazy(() => import('views/customer-request/CustomerBankDetails')));
const AppQuotationRequestList = Loadable(lazy(() => import('views/customer-request/quotation/QuotationRequestList')));
const AppSampleList = Loadable(lazy(() => import('views/customer-request/sample/SampleRequestList')));
const AppOrdersList = Loadable(lazy(() => import('views/customer-request/orders/OrdersList')));

// Quotations Routing
// const AppLeadList = Loadable(lazy(() => import('views/quotations/QuotationList')));
// const AppUpdateLead = Loadable(lazy(() => import('views/quotations/UpdateQuotation')));
// // const AppUpdateLead = Loadable(lazy(() => import('views/quotations/UpdateLead')));
// const AppLeadDetail = Loadable(lazy(() => import('views/quotations/QuotationDetail')));
// const AppLeadProfile = Loadable(lazy(() => import('views/quotations/QuotationProfile')));
// const AppLeadProfileEdit = Loadable(lazy(() => import('views/quotations/UpdateQuotationProfile')));
// const AppLeadAssignEdit = Loadable(lazy(() => import('views/quotations/assign/AssignQuotation')));
// const AppLeadHistory = Loadable(lazy(() => import('views/quotations/QuotationHistoryTab')));

// leads routing
const AppLeadList = Loadable(lazy(() => import('views/leads/leadsList')));
const AppAddLead = Loadable(lazy(() => import('views/leads/UpdateLead')));
const AppLeadProfile = Loadable(lazy(() => import('views/leads/leadProfile')));
const AppLeadDetail = Loadable(lazy(() => import('views/leads/LeadDetail')));
const AppLeadHistory = Loadable(lazy(() => import('views/leads/leadHistory')));
const AppFollowupList = Loadable(lazy(() => import('views/leads/follow-up/FollowupList')));
const AppAddFollowup = Loadable(lazy(() => import('views/leads/follow-up/UpdateFollowup')));
const AppAttachmentList = Loadable(lazy(() => import('views/leads/attachment/AttachmentList')));
const AppPerformaList = Loadable(lazy(() => import('views/leads/Performa/PerformaList')));
const AppAddPerforma = Loadable(lazy(() => import('views/leads/Performa/UpdateProforma')));
const AppCommentList = Loadable(lazy(() => import('views/leads/comments/CommentList')));
// inquirey routing
const AppInquiresList = Loadable(lazy(() => import('views/inquires/InquiriesList')));
const AppAddInquires = Loadable(lazy(() => import('views/inquires/Updateinquires')));
const AppInquiresDetail = Loadable(lazy(() => import('views/inquires/InquiresDetail')));
const AppInquiresProfile = Loadable(lazy(() => import('views/inquires/lnquiresProfile')));
const AppInquiresHistory = Loadable(lazy(() => import('views/inquires/lnquireyHistory')));
const AppFollowupInquireyList = Loadable(lazy(() => import('views/inquires/follow-up/FollowupList')));
const AppAddInquiresFollowup = Loadable(lazy(() => import('views/inquires/follow-up/UpdateFollowup')));
const AppAttachmentInquiryList = Loadable(lazy(() => import('views/inquires/attachment/AttachmentList')));
const AppCommentInquiryList = Loadable(lazy(() => import('views/inquires/comments/CommentList')));
const AppPerformaInquiryList = Loadable(lazy(() => import('views/inquires/Performa/PerformaList')));
const AppAddInquiryPerforma = Loadable(lazy(() => import('views/inquires/Performa/UpdateProforma')));
// Sample List
// const AppOrderList = Loadable(lazy(() => import('views/orders/OrderList')));
// const AppAddOrdersList = Loadable(lazy(() => import('views/orders/UpdateOrders')));
// Staff Routing
const AppStaffList = Loadable(lazy(() => import('views/staff/StaffList')));
const AppUpdateStaff = Loadable(lazy(() => import('views/staff/UpdateStaff')));
const AppStaffDetails = Loadable(lazy(() => import('views/staff/StaffDetails')));
const AppStaffProfile = Loadable(lazy(() => import('views/staff/StaffProfile')));
const AppStaffAttendance = Loadable(lazy(() => import('views/staff/StaffAttendance')));
const AppStaffLeave = Loadable(lazy(() => import('views/staff/StaffLeave')));
const AppStaffApplyLeave = Loadable(lazy(() => import('views/staff/ApplyLeave')));
const AppStaffAccountDetails = Loadable(lazy(() => import('views/staff/account/AccountDetails')));
const AppStaffApplyAccountDetails = Loadable(lazy(() => import('views/staff/account/UpdateAccount')));
const AppStaffSecurity = Loadable(lazy(() => import('views/staff/UpdateSecurity')));
const AppStaffUpdatePassword = Loadable(lazy(() => import('views/staff/UpdatePassword')));
const AppUpdateStaffDetails = Loadable(lazy(() => import('views/staff/EditStaffDetail')));
const AppStaffWarehouseDetails = Loadable(lazy(() => import('views/staff/warehouse/WarehouseDetails')));
const AppStaffUpdateWarehouse = Loadable(lazy(() => import('views/staff/warehouse/UpdateWarehouse')));

// =============================== //
// Tickets Routing
const AppTickets = Loadable(lazy(() => import('views/ticket/SupportTickets')));

// Reports Routing
const AppReports = Loadable(lazy(() => import('views/reports/Reports')));

// =========== || HRM SETTING || ================= //

// Attendance Routing
const AppAttendanceList = Loadable(lazy(() => import('views/hrm/attendance/AttendanceList')));

// Leaves Routing
const AppLeaveList = Loadable(lazy(() => import('views/hrm/leave/LeaveList')));
const AppUpdateLeave = Loadable(lazy(() => import('views/hrm/leave/UpdateLeave')));

// Announcement Routing
const AppAnnouncementList = Loadable(lazy(() => import('views/hrm/announcement/AnnouncementList')));
const AppUpdateAnnouncement = Loadable(lazy(() => import('views/hrm/announcement/UpdateAnnouncement')));

// Hiring Routing
const AppHiringList = Loadable(lazy(() => import('views/hrm/Hiring/HiringList')));
const AppUpdateHiring = Loadable(lazy(() => import('views/hrm/Hiring/UpdateHiring')));
// ========= || SETTING ROUTING || ===================== //
// Department Routing
const AppDepartmentList = Loadable(lazy(() => import('views/settings/department/DepartmentList')));
const AppUpdateDepartment = Loadable(lazy(() => import('views/settings/department/UpdateDepartment')));

// Documents Routing
const AppDocumentsTypesList = Loadable(lazy(() => import('views/settings/documents/DocumentsTypesList')));
const AppUpdateDocumentsTypes = Loadable(lazy(() => import('views/settings/documents/UpdateDocumentsTypes')));

// Role Routing
const AppRoleList = Loadable(lazy(() => import('views/settings/role/RoleList')));
const AppUpdateRole = Loadable(lazy(() => import('views/settings/role/UpdateRole')));

// Countries  Routing
const AppCountriesList = Loadable(lazy(() => import('views/settings/places/countries/CountryName')));
const AppUpdateCountries = Loadable(lazy(() => import('views/settings/places/countries/UpdateCountryName')));

// States Routing
const AppStatesList = Loadable(lazy(() => import('views/settings/places/states/StateName')));
const AppUpdateStates = Loadable(lazy(() => import('views/settings/places/states/UpdateStateName')));

// Cities Routing
const AppCitiesList = Loadable(lazy(() => import('views/settings/places/cities/CityName')));
const AppUpdateCities = Loadable(lazy(() => import('views/settings/places/cities/UpdateCityName')));

// Shift Routing
const AppShiftList = Loadable(lazy(() => import('views/settings/shift/ShiftList')));
const AppUpdateShift = Loadable(lazy(() => import('views/settings/shift/UpdateShift')));

// Leave Type Routing
const AppLeaveType = Loadable(lazy(() => import('views/settings/leave-type/LeaveTypeList')));
const AppUpdateLeaveType = Loadable(lazy(() => import('views/settings/leave-type/UpdateLeaveType')));

// Holidays Routing
const AppHolidayList = Loadable(lazy(() => import('views/settings/holiday/HolidayList')));
const AppUpdateHoliday = Loadable(lazy(() => import('views/settings/holiday/UpdateHoliday')));

// Holidays Routing
const AppCategoryList = Loadable(lazy(() => import('views/settings/category/CategoryList')));
const AppUpdateCategory = Loadable(lazy(() => import('views/settings/category/UpdateCategory')));

// Status Routing
const AppSourceList = Loadable(lazy(() => import('views/settings/source/SourceList')));
const AppUpdateSource = Loadable(lazy(() => import('views/settings/source/UpdateSource')));

// Status Routing
const AppStatusList = Loadable(lazy(() => import('views/settings/status/StatusList')));
const AppUpdateStatus = Loadable(lazy(() => import('views/settings/status/UpdateStatus')));

// google calender routing
const AppGoogleCalender = Loadable(lazy(() => import('views/calendar')));

// jobrole Routing
const AppJobRoleList = Loadable(lazy(() => import('views/settings/Job-role/jobRoleList')));
const AppUpdateJobRole = Loadable(lazy(() => import('views/settings/Job-role/UpdateJobRole')));

// sales
const AppSalesList = Loadable(lazy(() => import('views/sales/PerformaList')));
const AppUpdateSales = Loadable(lazy(() => import('views/sales/UpdateProforma')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            state: { permissionID: 'All' },
            element: (
                <AuthGuard>
                    <RoleGuard userPermission={['Admin', 'Client', 'Staff']}>
                        <Dashboard />
                    </RoleGuard>
                </AuthGuard>
            )
        },
        {
            path: '/dashboard',
            state: { permissionID: 'All' },
            element: (
                <RoleGuard userPermission={['Admin', 'Client', 'Staff']}>
                    <Dashboard />
                </RoleGuard>
            )
        },
        {
            path: '/clients',
            element: (
                <RoleGuard permissionID="Client-read" userPermission={['Admin', 'Staff']}>
                    <AppClientList />
                </RoleGuard>
            )
        },
        {
            path: '/clients/add',
            element: (
                <RoleGuard permissionID="Client-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateClient pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/clients/:clientID/edit',
            element: (
                <RoleGuard permissionID="Client-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateClient pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/clients/:clientID',
            element: (
                <RoleGuard permissionID="Client-read" userPermission={['Admin', 'Staff']}>
                    <AppClientDetail title="Client Details" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/clients/:clientID',
                    element: (
                        <RoleGuard permissionID="Client-read" userPermission={['Admin', 'Staff']}>
                            <AppClientProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/profile',
                    element: (
                        <RoleGuard permissionID="Client-read" userPermission={['Admin', 'Staff']}>
                            <AppClientProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Client-write" userPermission={['Admin', 'Staff']}>
                            <AppClientProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/client-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppClientHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation/add',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppUpdateQuotation pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/quotation/:quotationID',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppUpdateQuotation pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                // {
                //     path: '/clients/:clientID/quotation/:quotationID/edit',
                //     element: (
                //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                //             <AppUpdateQuotation pageBehaviour="Edit" />
                //         </RoleGuard>
                //     )
                // },
                {
                    path: '/clients/:clientID/invoice',
                    element: (
                        <RoleGuard permissionID="Invoice-read" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppInvoiceList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/invoice/add',
                    element: (
                        <RoleGuard permissionID="invoice-write" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppUpdateInvoice pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/clients/:clientID/invoice/:invoiceID',
                    element: (
                        <RoleGuard permissionID="invoice-write" userPermission={['Admin', 'Client', 'Staff']}>
                            <AppUpdateInvoice pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'Attachment',
                    element: <AppAttachmentClientList pageBehaviour="view" />
                },
                {
                    path: 'client-users',
                    element: <AppUsersList pageBehaviour="view" />
                },
                {
                    path: 'client-performa',
                    element: <AppClientPerformaList pageBehaviour="view" />
                },
                {
                    path: 'client-performa/add',
                    element: <AppAddClientPerforma pageBehaviour="add" />
                },
                {
                    path: 'client-performa/:ProformaID/edit',
                    element: <AppAddClientPerforma pageBehaviour="Edit" />
                },
                {
                    path: 'client-notes',
                    element: <AppNotesList pageBehaviour="view" />
                }
            ]
        },
        {
            path: '/sample-request',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppSampleRequestList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/sample-request/add',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppAddSampleRequestList pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/sample-request/:sampleRequestID/edit',
            element: (
                <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
                    <AppAddSampleRequestList pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/sample-request/:sampleRequestID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppSampleDetail title="Sample request" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/sample-request/:sampleRequestID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSampleProfile title="Sample request" pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/sample-request/:sampleRequestID/sample-assign',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/sample-request/:sampleRequestID/sample-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
                // {
                //     path: 'orders',
                //     element: (
                //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                //             <AppSampleOrdersList pageBehaviour="view" />
                //         </RoleGuard>
                //     )
                // }
            ]
        },
        // {
        //     path: '/orders',
        //     element: (
        //         <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
        //             <AppOrderList pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/orders/add',
        //     element: (
        //         <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
        //             <AppAddOrdersList pageBehaviour="Add" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/orders/:sampleRequestID/edit',
        //     element: (
        //         <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
        //             <AppAddOrdersList pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },

        // ====================================================
        {
            path: '/social-lead',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppSocialLeadList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/social-lead/add',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppAddSocialLead pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/social-lead/:LeadID/edit',
            element: (
                <RoleGuard permissionID="Requestest-write" userPermission={['Admin']}>
                    <AppAddSocialLead pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/social-lead/:LeadID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppSocialLeadDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/social-lead/:LeadID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSocialLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSocialLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppSocialLeadProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/lead-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSampleLeadHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/lead-assign',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSocialAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client']}>
                            <AppSocialQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadIDs/quotation',
                    element: (
                        <RoleGuard permissionID="Quotation-read" userPermission={['Admin', 'Client']}>
                            <AppSocialQuotationList pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/quotation/add',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateSocialQuotation pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/social-lead/:LeadID/quotation/:quotationID',
                    element: (
                        <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
                            <AppUpdateSocialQuotation pageBehaviour="View" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/calendar',
            element: (
                <RoleGuard permissionID="Requestest-read" userPermission={['Admin', 'Staff']}>
                    <AppGoogleCalender pageBehaviour="View" />
                </RoleGuard>
            )
        },

        //  ===================================== Leads  Routing    =========================================
        {
            path: '/leads',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppLeadList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/leads/add',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppAddLead pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/leads/:LeadID/edit',
            element: (
                <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                    <AppAddLead pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/leads/:LeadID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppLeadDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/leads/:LeadID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/leads/:LeadID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppLeadProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/leads/:LeadID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppLeadProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/leads/:LeadID/lead-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppLeadHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'follow-up',
                    element: <AppFollowupList pageBehaviour="View" />
                },
                {
                    path: '/leads/:LeadID/follow-up/add',
                    element: <AppAddFollowup pageBehaviour="Add" />
                },
                {
                    path: '/leads/:LeadID/follow-up/:FollowupID/edit',
                    element: <AppAddFollowup pageBehaviour="Edit" />
                },
                {
                    path: 'Attachment',
                    element: <AppAttachmentList pageBehaviour="view" />
                },
                // ----------------------------------------
                {
                    path: 'Performa',
                    element: <AppPerformaList pageBehaviour="view" />
                },
                {
                    path: 'Performa/add',
                    element: <AppAddPerforma pageBehaviour="Add" />
                },
                {
                    path: 'Performa/:ProformaID/edit',
                    element: <AppAddPerforma pageBehaviour="Edit" />
                },
                // --------------------------------------------------
                {
                    path: 'comments',
                    element: <AppCommentList pageBehaviour="view" />
                }
            ]
        },
        // =========================================================================================
        {
            path: '/inquires',
            element: (
                <RoleGuard permissionID="Inquiry-read" userPermission={['Admin', 'Staff']}>
                    <AppInquiresList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/inquires/add',
            element: (
                <RoleGuard permissionID="Inquiry-read" userPermission={['Admin', 'Staff']}>
                    <AppAddInquires pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/inquires/:InquiryID/edit',
            element: (
                <RoleGuard permissionID="Inquiry-write" userPermission={['Admin', 'Staff']}>
                    <AppAddInquires pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/inquires/:InquiryID',
            element: (
                <RoleGuard permissionID="Inquiry-read" userPermission={['Admin', 'Staff']}>
                    <AppInquiresDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/inquires/:InquiryID',
                    element: (
                        <RoleGuard permissionID="Inquiry-read" userPermission={['Admin', 'Staff']}>
                            <AppInquiresProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/inquires/:InquiryID/profile',
                    element: (
                        <RoleGuard permissionID="Inquiry-read" userPermission={['Admin', 'Staff']}>
                            <AppInquiresProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/inquires/:InquiryID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Inquiry-write" userPermission={['Admin', 'Staff']}>
                            <AppInquiresProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/inquires/:InquiryID/inquiry-history',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppInquiresHistory pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'Attachment',
                    element: <AppAttachmentInquiryList pageBehaviour="view" />
                },
                {
                    path: 'follow-up',
                    element: <AppFollowupInquireyList pageBehaviour="View" />
                },
                {
                    path: 'follow-up/add',
                    element: <AppAddInquiresFollowup pageBehaviour="Add" />
                },
                {
                    path: 'follow-up/:FollowupID/edit',
                    element: <AppAddInquiresFollowup pageBehaviour="Edit" />
                },
                {
                    path: 'comments',
                    element: <AppCommentInquiryList pageBehaviour="view" />
                },
                // ----------------------------------------
                {
                    path: 'Performa',
                    element: <AppPerformaInquiryList pageBehaviour="view" />
                },
                {
                    path: 'Performa/add',
                    element: <AppAddInquiryPerforma pageBehaviour="Add" />
                },
                {
                    path: 'Performa/:ProformaID/edit',
                    element: <AppAddInquiryPerforma pageBehaviour="Edit" />
                }
                // --------------------------------------------------
            ]
        },

        // ===========================================================================================
        {
            path: '/customers',
            element: (
                <RoleGuard permissionID="CustomerRequest-read" userPermission={['Admin', 'Staff']}>
                    <AppCustomerRequestList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/add',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AddCustomerBasicDetail pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/details',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AppAddCustomerRequest pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/details/:customerDetailsID/edit',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AppAddCustomerRequest pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/bankdetails',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AddCustomerBankDetail pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/bankdetails/:customerDetailsID/edit',
            element: (
                <RoleGuard permissionID="CustomerRequest-write" userPermission={['Admin', 'Staff']}>
                    <AddCustomerBankDetail pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID/edit',
            element: (
                <RoleGuard permissionID="CustomerRequest-read" userPermission={['Admin', 'Staff']}>
                    <AddCustomerBasicDetail pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/customers/:customerRequestID',
            element: (
                <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                    <AppCustomerDetail title="Lead" pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/customers/:customerRequestID',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCustomerProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID/profile',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppCustomerProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID/profile-edit',
                    element: (
                        <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
                            <AppCustomerProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID/bank-details',
                    element: (
                        <RoleGuard permissionID="Lead-history-read" userPermission={['Admin', 'Staff']}>
                            <CustomerBankInfo pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/customers/:customerRequestID/social-assign',
                    element: (
                        <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
                            <AppSocialLeadAssignEdit pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'quotation-request',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppQuotationRequestList pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'sample-request',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppSampleList pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: 'orders',
                    element: (
                        <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
                            <AppOrdersList pageBehaviour="view" />
                        </RoleGuard>
                    )
                }
            ]
        },

        // {
        //     path: '/quotation-request',
        //     element: (
        //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //             <AppLeadList pageBehaviour="view" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/add',
        //     element: (
        //         <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
        //             <AppUpdateLead pageBehaviour="Add" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/edit',
        //     element: (
        //         <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
        //             <AppUpdateLead pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID',
        //     element: (
        //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //             <AppLeadDetail title="Lead" pageBehaviour="View" />
        //         </RoleGuard>
        //     ),
        //     children: [
        // {
        //     path: '/quotation-request/:leadID',
        //     element: (
        //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //             <AppLeadProfile pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/profile',
        //     element: (
        //         <RoleGuard permissionID="Lead-read" userPermission={['Admin', 'Staff']}>
        //             <AppLeadProfile pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/profile-edit',
        //     element: (
        //         <RoleGuard permissionID="Lead-write" userPermission={['Admin', 'Staff']}>
        //             <AppLeadProfileEdit pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/leads-assign',
        //     element: (
        //         <RoleGuard permissionID="Lead-assign-write" userPermission={['Admin', 'Staff']}>
        //             <AppLeadAssignEdit pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/leads-history',
        //     element: (
        //         <RoleGuard permissionID="Lead-history-read" userPermission={['Admin', 'Staff']}>
        //             <AppLeadHistory pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '',
        //     element: (
        //         <RoleGuard permissionID="Quotation-read" userPermission={['Admin']}>
        //             <AppQuotationLeadList pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/quotation',
        //     element: (
        //         <RoleGuard permissionID="Quotation-read" userPermission={['Admin']}>
        //             <AppQuotationLeadList pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/quotation/add',
        //     element: (
        //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin']}>
        //             <AppUpdateQuotationLead pageBehaviour="Add" />
        //         </RoleGuard>
        //     )
        // },
        // {
        //     path: '/quotation-request/:leadID/quotation/:quotationID',
        //     element: (
        //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin']}>
        //             <AppUpdateQuotationLead pageBehaviour="View" />
        //         </RoleGuard>
        //     )
        // }
        // {
        //     path: '/leads/:leadID/quotation/:quotationID/edit',
        //     element: (
        //         <RoleGuard permissionID="Quotation-write" userPermission={['Admin', 'Client']}>
        //             <AppUpdateQuotationLead pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // }
        //     ]
        // },
        {
            path: '/sales',
            element: (
                <RoleGuard permissionID="Announcement-read" userPermission={['Admin', 'Staff']}>
                    <AppSalesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/sales/add',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateSales pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        // {
        //     path: 'setting/job-role/:jobRoleId/edit',
        //     element: (
        //         <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
        //             <AppUpdateJobRole pageBehaviour="Edit" />
        //         </RoleGuard>
        //     )
        // },
        {
            path: '/staff',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                    <AppStaffList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/staff/add',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStaff pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/staff/:staffID/edit',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateStaff pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/staff/:staffID',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Client', 'Staff']}>
                    <AppStaffDetails title="Staff Details" pageBehaviour="view" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/staff/:staffID',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/profile',
                    element: (
                        <RoleGuard permissionID="Profile-read" userPermission={['Admin', 'client', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/edit-details',
                    element: (
                        <RoleGuard permissionID="Detail-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateStaffDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/attendance',
                    element: (
                        <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/leave',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/apply-leave',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/account-details',
                    element: (
                        <RoleGuard permissionID="Account-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAccountDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/apply-account-details',
                    element: (
                        <RoleGuard permissionID="Account-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyAccountDetails pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/edit-account-details',
                    element: (
                        <RoleGuard permissionID="Account-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyAccountDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse',
                    element: (
                        <RoleGuard permissionID="Warehouse-read" userPermission={['Admin']}>
                            <AppStaffWarehouseDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse/add',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse/:userwarehouseID/edit',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/security',
                    element: (
                        <RoleGuard permissionID="Security-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffSecurity pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/update-password',
                    element: (
                        <RoleGuard permissionID="update-password-write" userPermission={['Admin']}>
                            <AppStaffUpdatePassword pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/weekly-result',
            element: (
                <RoleGuard permissionID="WeeklyResult-read" userPermission={['Admin', 'Client']}>
                    <WeeklyResultList title="weekly-result" pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/weekly-result/add',
            element: (
                <RoleGuard permissionID="WeeklyResult-write" userPermission={['Admin']}>
                    <WeeklyResultForm pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/weekly-result/:WeeklyResultID/edit',
            element: (
                <RoleGuard permissionID="WeeklyResult-write" userPermission={['Admin']}>
                    <WeeklyResultForm pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/me',
            element: (
                <RoleGuard permissionID="All" userPermission={['Admin', 'Staff']}>
                    <AppStaffDetails title="My Profile" pageBehaviour="view" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/me',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/attendance',
                    element: (
                        <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/leave',
                    element: (
                        <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/apply-leave',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/me/security',
                    element: (
                        <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffSecurity pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/hrm/attendance',
            element: (
                <RoleGuard permissionID="Attendance-read" userPermission={['Admin']}>
                    <AppAttendanceList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves',
            element: (
                <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                    <AppLeaveList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/add',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/:leaveID/edit',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements',
            element: (
                <RoleGuard permissionID="Announcement-read" userPermission={['Admin', 'Staff']}>
                    <AppAnnouncementList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/add',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateAnnouncement pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/:announcementID/edit',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateAnnouncement pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets',
            element: (
                <RoleGuard permissionID="SupportTicket-read" userPermission={['Admin']}>
                    <AppTickets pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/reports',
            element: (
                <RoleGuard permissionID="Reports-read" userPermission={['Admin']}>
                    <AppReports pageBehaviour="View" />
                </RoleGuard>
            )
        },
        // hiring routes ============================================================================
        {
            path: '/hrm/Hiring',
            element: (
                <RoleGuard permissionID="Role-read" userPermission={['Admin']}>
                    <AppHiringList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/Hiring/add',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateHiring pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/Hiring/:HiringID/edit',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateHiring pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ================================================================================
        {
            path: '/setting/roles',
            element: (
                <RoleGuard permissionID="Role-read" userPermission={['Admin']}>
                    <AppRoleList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/add',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/edit',
            element: (
                <RoleGuard permissionID="Role-write" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/',
            element: (
                <RoleGuard permissionID="Role-read" userPermission={['Admin']}>
                    <AppUpdateRole pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/departments',
            element: (
                <RoleGuard permissionID="Department-read" userPermission={['Admin']}>
                    <AppDepartmentList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/departments/add',
            element: (
                <RoleGuard permissionID="Department-write" userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/departments/:departmentID/edit',
            element: (
                <RoleGuard permissionID="Department-write" userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documents-types',
            element: (
                <RoleGuard permissionID="Document-read" userPermission={['Admin']}>
                    <AppDocumentsTypesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/add',
            element: (
                <RoleGuard permissionID="Document-write" userPermission={['Admin']}>
                    <AppUpdateDocumentsTypes pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/:documentsTypesID/edit',
            element: (
                <RoleGuard permissionID="Document-write" userPermission={['Admin']}>
                    <AppUpdateDocumentsTypes pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries',
            element: (
                <RoleGuard permissionID="Country-read" userPermission={['Admin']}>
                    <AppCountriesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/add',
            element: (
                <RoleGuard permissionID="Country-write" userPermission={['Admin']}>
                    <AppUpdateCountries pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/:countryID/edit',
            element: (
                <RoleGuard permissionID="Country-write" userPermission={['Admin']}>
                    <AppUpdateCountries pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states',
            element: (
                <RoleGuard permissionID="State-read" userPermission={['Admin']}>
                    <AppStatesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/add',
            element: (
                <RoleGuard permissionID="State-write" userPermission={['Admin']}>
                    <AppUpdateStates pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/:statesID/edit',
            element: (
                <RoleGuard permissionID="State-write" userPermission={['Admin']}>
                    <AppUpdateStates pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities',
            element: (
                <RoleGuard permissionID="City-read" userPermission={['Admin']}>
                    <AppCitiesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/add',
            element: (
                <RoleGuard permissionID="City-write" userPermission={['Admin']}>
                    <AppUpdateCities pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/:citiesID/edit',
            element: (
                <RoleGuard permissionID="City-write" userPermission={['Admin']}>
                    <AppUpdateCities pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift',
            element: (
                <RoleGuard permissionID="Shift-read" userPermission={['Admin']}>
                    <AppShiftList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/add',
            element: (
                <RoleGuard permissionID="Shift-write" userPermission={['Admin']}>
                    <AppUpdateShift pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/:shiftID/edit',
            element: (
                <RoleGuard permissionID="Shift-write" userPermission={['Admin']}>
                    <AppUpdateShift pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type',
            element: (
                <RoleGuard permissionID="LeaveType-read" userPermission={['Admin']}>
                    <AppLeaveType pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type/add',
            element: (
                <RoleGuard permissionID="LeaveType-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeaveType pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type/:leaveTypeID/edit',
            element: (
                <RoleGuard permissionID="LeaveType-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeaveType pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays',
            element: (
                <RoleGuard permissionID="Holiday-read" userPermission={['Admin']}>
                    <AppHolidayList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays/add',
            element: (
                <RoleGuard permissionID="Holiday-write" userPermission={['Admin']}>
                    <AppUpdateHoliday pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/holidays/:holidayID/edit',
            element: (
                <RoleGuard permissionID="Holiday-write" userPermission={['Admin']}>
                    <AppUpdateHoliday pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ================================================================================
        {
            path: '/settings/category',
            element: (
                <RoleGuard permissionID="Category-read" userPermission={['Admin']}>
                    <AppCategoryList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/settings/category/add',
            element: (
                <RoleGuard permissionID="Category-write" userPermission={['Admin']}>
                    <AppUpdateCategory pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/settings/category/:categoryID/edit',
            element: (
                <RoleGuard permissionID="Category-write" userPermission={['Admin']}>
                    <AppUpdateCategory pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // ====================================================================================
        {
            path: '/setting/source',
            element: (
                <RoleGuard permissionID="Source-read" userPermission={['Admin']}>
                    <AppSourceList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/source/add',
            element: (
                <RoleGuard permissionID="source-write" userPermission={['Admin']}>
                    <AppUpdateSource pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/source/:sourceID/edit',
            element: (
                <RoleGuard permissionID="source-write" userPermission={['Admin']}>
                    <AppUpdateSource pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        // ============================================================================================
        {
            path: '/setting/job-role',
            element: (
                <RoleGuard permissionID="Announcement-read" userPermission={['Admin', 'Staff']}>
                    <AppJobRoleList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/job-role/add',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateJobRole pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: 'setting/job-role/:jobRoleId/edit',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateJobRole pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        //  ============================================================================================
        {
            path: '/setting/status',
            element: (
                <RoleGuard permissionID="Status-read" userPermission={['Admin']}>
                    <AppStatusList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/status/add',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateStatus pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/status/:statusID/edit',
            element: (
                <RoleGuard permissionID="Status-write" userPermission={['Admin']}>
                    <AppUpdateStatus pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '*',
            element: <Error />
        },
        {
            path: '/profile',
            element: (
                <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'staff']}>
                    <AppProfile pageBehaviour="View" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/profile',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'staff']}>
                            <UpdateProfile pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-profile',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'staff']}>
                            <UpdateProfile pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/update-security',
                    element: (
                        <RoleGuard permissionID="All" userPermission={['Admin', 'Client', 'staff']}>
                            <UpdateSecurity pageBehaviour="View" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/connection-info',
                    element: (
                        <RoleGuard permissionID="ConnectionInfo-write" userPermission={['Client', 'staff']}>
                            <AppClientConnectionEdit pageBehaviour="Edit" clientComponent="true" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/profile/connection-info/add',
                    element: (
                        <RoleGuard permissionID="ConnectionInfo-write" userPermission={['Client', 'staff']}>
                            <AppClientConnectionEdit pageBehaviour="Add" clientComponent="true" />
                        </RoleGuard>
                    )
                }
            ]
        }
    ]
};

export default MainRoutes;
