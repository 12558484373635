import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SET_USER } from 'store/actionType';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

import { globalAction } from 'store';
// constant
const initialState = {
    userLoggedIn: false,
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        axios.defaults.headers.common.locale = `es`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const storeDispatch = useDispatch();
    const init = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken) {
                setSession(serviceToken);
                const response = await axios.get('/api/users/me');
                if (response.data.status === 'success') {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: response.data.user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: LOGOUT
            });
        }
    };
    useEffect(() => {
        init();
    }, []);

    const login = async (email, password) => {
        try {
            let response = await axios.post('/api/users/login', { email, password });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user,
                        userLoggedIn: true
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const sendOtp = async (email) => {
        await axios.post('/api/users/send-otp', email);
    };

    const loginWithOTP = async (email, otp) => {
        try {
            let response = await axios.post('/api/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const verifyOTP = async (email, otp) => {
        try {
            let response = await axios.post('/api/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }
            if (response.status === 'success') {
                setSession(response.accessToken);
                navigate('/reset-password', { replace: true, state: { email, otp } });
                dispatch({
                    type: SET_USER,
                    payload: {
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const verifyEmail = async (email, otp) => {
        try {
            let response = await axios.post('/api/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }
            if (response.status === 'success') {
                navigate('/login', { replace: true });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const resetPassword = async (values) => {
        try {
            let response = await axios.post('/api/users/forget-password', values);
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }
            if (response.status === 'success') {
                navigate('/login');
                // setSession(response.accessToken);
                // dispatch({
                //     type: LOGIN,
                //     payload: {
                //         isLoggedIn: true,
                //         user: response.user
                //     }
                // });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };
    const googlelogin = async (token) => {
        try {
            let response = await axios.post('/api/users/google-login', { token });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }
            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user,
                        userLoggedIn: true
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };
    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                init,
                googlelogin,
                login,
                logout,
                sendOtp,
                verifyOTP,
                resetPassword,
                updateProfile,
                loginWithOTP,
                verifyEmail
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
