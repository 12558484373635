import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { get } from 'utils/axiosRequests';

const initialState = {
    notifications: [],
    notificationCount: 0
};

const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {
        // setNotifications(state, action) {
        //     console.log(action, 'notificationsdds');
        //     const { payload } = action;
        //     state.notifications = [...payload];
        //     state.notificationCount = state.notifications.length;
        // },
        setNotifications(state, action) {
            const { payload } = action;
            console.log(payload, 'payloadpayloadpayloadpayload');
            // Make sure payload is an array before spreading it
            if (Array.isArray(payload)) {
                state.notifications = [...payload];
                // state.notificationCount = payload.length;
            } else {
                console.error('Payload is not an array in setNotifications reducer');
            }
        },

        setNotificationsCount(state, payload) {
            state.notificationCount = payload.payload;
        },

        addNotification(state, action) {
            if (!Array.isArray(state.notifications)) {
                state.notifications = []; // Initialize as an empty array if not already
            }
            state.notifications = [...state.notifications, action.payload];
            state.notificationCount = state.notifications.length;
        },

        // addNotification(state, action) {
        //     state.notifications = [...state.notifications, action.payload];
        //     state.notificationCount += 1;
        // },
        // markAllAsRead(state) {
        //     state.notifications.forEach((notification) => (notification.readStatus = true));
        //     state.notificationCount = 0;
        // },

        clearNotifications(state) {
            state.notifications = [];
            state.notificationCount = 0;
        }
    }
});

export default notificationSlice.reducer;

export const { setNotifications, addNotification, markAllAsRead, clearNotifications } = notificationSlice.actions;

export const getNotification = () => {
    get('/api/notifications').then((res) => dispatch(notificationSlice.actions.setNotifications(res.data)));
};

export const getNotificationCount = async () => {
    try {
        await get('/api/notifications/count').then((res) => dispatch(notificationSlice.actions.setNotificationsCount(res.data)));
    } catch {
        console.log();
    }
};
