import { Link, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import useAuth from 'hooks/useAuth';
import MainLogo from '../../../assets/images/spirehub-logo.webp';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AuthEmailVerification from './auth-forms/AuthEmailVerification';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { useEffect, useState } from 'react';

// assets

// ===========================|| AUTH3 - CODE VERIFICATION ||=========================== //

const VerifyEmail = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { resetPassword } = useAuth();
    const email = query.get('email');

    useEffect(() => {
        if (!email) navigate('/login');
    }, [email]);

    const [resendCodeResponse, setResendCodeResponse] = useState('');
    const handleResendOtp = () => {
        resetPassword({ email });
        setResendCodeResponse('Code sent done, please check email!');
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <img src={MainLogo} alt="Pure Element" />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Enter Verification Code
                                                    </Typography>
                                                    <Typography variant="subtitle1" fontSize="1rem">
                                                        We send you on mail.
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="0.875rem"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        We’ve send you code on {email}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AuthEmailVerification email={email} />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Divider />
                                    </Grid> */}
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default VerifyEmail;
