// third-party
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import useHandleForm from 'hooks/useHandleForm';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage, useIntl } from 'react-intl';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, TextField, Stack, FormGroup, FormControlLabel, Checkbox, Button, Avatar, MenuItem } from '@mui/material';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import { Label } from '@mui/icons-material';

// =============================|| Weekly-Result||============================= //

const validationSchema = yup.object({
    weekName: yup.string().required('Week is required.'),
    numberOfCall: yup.string().required('Number Of Calls is required.')
});

const WeeklyResultForm = ({ pageBehaviour }) => {
    const theme = useTheme();
    const intl = useIntl();
    const { WeeklyResultID } = useParams();
    const navigate = useNavigate();
    const formatDate = (data) => moment(data).format('YYYY-MM-DD');
    const [callDetails, setCallDetails] = useState([]);
    const [contactName, setContactName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [callResult, setCallResult] = useState('');

    const formikInitialValues = {
        weekName: '',
        numberOfCall: '',
        callDetails,
        source: 'Dashboard'
    };
    const url = React.useMemo(
        () => (pageBehaviour === 'Add' ? '/weekly-results' : `/weekly-results/${WeeklyResultID}`),
        [pageBehaviour, WeeklyResultID]
    );

    const [formik, isLoading, currentData] = useHandleForm(`/api${url}`, pageBehaviour, formikInitialValues, validationSchema);

    const addMoreCalls = () => {
        const array = [...callDetails];
        array.push({
            contactName,
            companyName,
            callResult
        });
        setCallDetails(array);
        setContactName('');
        setCompanyName('');
        setCallResult('');
    };
    // function deleteitem(index) {
    //     if (index >= 0 && index < weeklyResult.length) {
    //         weeklyResult.filter(index, 1);
    //         displaylist();
    //     }
    // }
    const handleDeleteClick = (key) => {
        const updatedCallDetails = [...callDetails];
        updatedCallDetails.splice(key, 1);
        setCallDetails(updatedCallDetails);
    };
    const [editIndex, setEditIndex] = useState(null);
    const handleEditClick = (key) => {
        setEditIndex(key);
        const editedItem = callDetails[key];
        setContactName(editedItem.contactName);
        setCompanyName(editedItem.companyName);
        setCallResult(editedItem.callResult);
    };
    // const [update, setUpdate] = useState();
    const handleUpdateClick = () => {
        if (editIndex !== null) {
            const updatedCallDetails = [...callDetails];
            updatedCallDetails[editIndex] = {
                contactName,
                companyName,
                callResult
            };
            setCallDetails(updatedCallDetails);
            setEditIndex(null);
            setContactName('');
            setCompanyName('');
            setCallResult('');
        }
    };
    return (
        <MainCard
            title={`${pageBehaviour} ${intl.formatMessage({ id: 'Weekly Results' })}`}
            content={false}
            secondary={
                <Button variant="contained" onClick={() => navigate(-1)}>
                    <FormattedMessage id="Back" />
                </Button>
            }
        >
            <form onSubmit={formik.handleSubmit} style={{ textTransform: 'uppercase' }}>
                <Grid container spacing={gridSpacing} sx={{ p: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="weekName"
                            name="weekName"
                            label={intl.formatMessage({ id: 'Week' })}
                            // InputLabelProps={{ shrink: true }}
                            value={formik.values.weekName}
                            onChange={formik.handleChange}
                            error={formik.touched.weekName && Boolean(formik.errors.weekName)}
                            helperText={formik.touched.weekName && formik.errors.weekName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="numberOfCall"
                            name="numberOfCall"
                            label={intl.formatMessage({ id: 'Number of Calls' })}
                            type="number"
                            // InputLabelProps={{ shrink: true }}
                            value={formik.values.numberOfCall}
                            onChange={formik.handleChange}
                            error={formik.touched.numberOfCall && Boolean(formik.errors.numberOfCall)}
                            helperText={formik.touched.numberOfCall && formik.errors.numberOfCall}
                        />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                            <FormattedMessage id="Call Details" />
                        </Typography>
                        {callDetails?.length > 0 && (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                {' '}
                                                <FormattedMessage id="Contact Name*" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormattedMessage id="company name*" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormattedMessage id="Call Result" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormattedMessage id="Action" />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {callDetails &&
                                            callDetails.map((call, key) => (
                                                <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" component="th" scope="row">
                                                        {call?.contactName}
                                                    </TableCell>
                                                    <TableCell align="left"> {call?.companyName}</TableCell>
                                                    <TableCell align="left"> {call?.callResult}</TableCell>
                                                    <TableCell align="left">
                                                        <Stack direction="row" gap={2} alignItems="center">
                                                            <EditOutlinedIcon onClick={() => handleEditClick(key)} />
                                                            <DeleteOutlineOutlinedIcon onClick={() => handleDeleteClick(key)} />
                                                            <Button onClick={handleUpdateClick}>
                                                                <FormattedMessage id="Update" />
                                                            </Button>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="contactName"
                            name="contactName"
                            label={intl.formatMessage({ id: 'Contact Name' })}
                            // InputLabelProps={{ shrink: true }}
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="companyName"
                            name="companyName"
                            label={intl.formatMessage({ id: 'company name*' })}
                            // InputLabelProps={{ shrink: true }}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="CallResult"
                            name="CallResult"
                            label={intl.formatMessage({ id: 'Call Result' })}
                            // InputLabelProps={{ shrink: true }}
                            value={callResult}
                            onChange={(e) => setCallResult(e.target.value)}
                        />
                    </Grid>
                    <Grid item={12}>
                        <Button variant="outlined" onClick={addMoreCalls}>
                            {intl.formatMessage({ id: 'Add more' })}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained">
                            {intl.formatMessage({ id: 'Submit' })}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </MainCard>
    );
};
export default WeeklyResultForm;
