// Header.js

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Badge, Box, IconButton, Menu, MenuItem, Tooltip, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import notification, {
    addNotification,
    markAllAsRead,
    setNotifications,
    clearNotifications,
    getNotification,
    getNotificationCount
} from 'store/slices/notification';
import { openDrawer } from 'store/slices/menu';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import useAuth from 'hooks/useAuth';
import { IconMenu2 } from '@tabler/icons';
import axiosServices from 'utils/axios';
import { globalAction } from 'store';
import notificationSound from '../../../assets/images/notification-sound.mp3';

const Header = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const { notifications, notificationCount } = useSelector((state) => state.notification);

    const [anchorEl, setAnchorEl] = useState(null);

    const firebaseConfig = {
        apiKey: 'AIzaSyAd5MUbVKs7q2VpfTIFSWotY0xrosHJaOY',
        authDomain: 'spirecrm.firebaseapp.com',
        projectId: 'spirecrm',
        storageBucket: 'spirecrm.appspot.com',
        messagingSenderId: '759351613833',
        appId: '1:759351613833:web:61ef89d59e4c9cbbe06958'
    };
    const fapp = initializeApp(firebaseConfig);
    const messaging = getMessaging(fapp);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMarkAllAsRead = () => {
        dispatch(markAllAsRead());
        handleClose();
        axiosServices.put(`/api/notifications/read-notification/${user?.id}`);
    };

    const fetchNotifications = async () => {
        try {
            const response = await axiosServices.get('/api/notifications');
            console.log(response, 'responsedffdfdfd');
            if (response.data.status === 'success') {
                const unreadNotifications = response.data.data.filter((notification) => !notification.readStatus);
                setUnreadNotificationCount(unreadNotifications.length);
                console.log(unreadNotifications, 'unreadNotifications');
                dispatch(setNotifications(response.data.data));
            }
        } catch (error) {
            console.error('Error fetching notifications', error);
        }
    };

    const handleNotificationClick = (notificationId, path, leadId) => {
        axiosServices.patch(`/api/notifications/read-notification/${notificationId}`).then((res) => {
            if (res.data.status === 'success') {
                navigate(`/${path}`);
            }
        });
        getNotification();
        getNotificationCount();
        fetchNotifications();
        handleClose();
    };

    const handleDeleteNotification = async () => {
        await axiosServices.delete(`/api/notifications/delete-all/${user?.id}`);
        dispatch(clearNotifications());
        setUnreadNotificationCount(0);
        handleClose();
    };
    useEffect(() => {
        getNotification();
        getNotificationCount();
    }, []);

    useEffect(() => {
        fetchNotifications();
    }, [dispatch]);

    const playSound = () => {
        const audio = new Audio(notificationSound);
        audio.play();
    };

    useEffect(() => {
        onMessage(messaging, (payload) => {
            playSound();
            if (payload?.notification) {
                dispatch(globalAction('SHOW_TOASTER', { status: 'success', message: payload?.notification?.title }));
                dispatch(
                    addNotification({
                        id: payload.messageId,
                        title: payload.notification.title,
                        createdAt: new Date().toISOString(),
                        readStatus: false,
                        path: payload.data.path,
                        leadId: payload.data.leadId
                    })
                );
                fetchNotifications();
            }
        });
    }, [dispatch, messaging]);

    return (
        <>
            {/* / logo & toggler button / */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Tooltip title="Recent-Notification" arrow>
                <IconButton sx={{ marginRight: '15px' }} onClick={handleClick}>
                    <Badge badgeContent={unreadNotificationCount} color="primary">
                        <NotificationsActiveOutlinedIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <div>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" padding="5px">
                        <Typography sx={{ color: 'primary', pl: 2, fontWeight: 500, fontSize: '16px' }}>
                            {notifications.length > 0 ? 'Recent Notifications' : 'No new notifications'}
                        </Typography>
                        {notifications.length > 0 && (
                            <>
                                <Tooltip arrow title="Clear all notifications">
                                    <IconButton size="small" onClick={handleDeleteNotification}>
                                        <ClearAllIcon sx={{ marginRight: 1, fontSize: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Stack>

                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                            <MenuItem
                                key={index}
                                sx={{
                                    backgroundColor: !notification.readStatus ? theme.palette.grey[200] : 'white',
                                    m: 1,
                                    borderRadius: 2
                                }}
                                onClick={() => handleNotificationClick(notification.id, notification.path, notification.leadId)}
                            >
                                <Stack direction="column" sx={{ pr: 2 }}>
                                    <Typography sx={{ textOverflow: 'ellipsis', maxWidth: '250px', overflow: 'hidden' }}>
                                        {notification.title}
                                    </Typography>
                                    <Typography sx={{ fontSize: '10px', justifyContent: 'flex-end' }} variant="body2">
                                        {moment(notification.createdAt).format('DD-MM-YYYY')} ,{' '}
                                        {moment(notification.createdAt).format('HH:mm')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))
                    ) : (
                        <Typography />
                    )}
                </div>
            </Menu>

            <ProfileSection />
        </>
    );
};

export default Header;
